import type { LinkBoxType } from './types';
import { LinkByText } from '../LinkByText';

export const LinkBoxCaption = ({ data, link }: { data: LinkBoxType; link: string }) => {
  const { field_button_text: buttonText, behavior_paragraph_cta_icon: buttonIcon } = data;
  return (
    buttonText && (
      <LinkByText
        data={{
          ...data,
          type: 'link_by_text',
          field_link: link,
          field_text: buttonText,
          field_icon: buttonIcon ? 'ArrowRight' : undefined,
        }}
        sx={{
          width: '100%',
          color: 'text.dark',
          fontWeight: 600,
          mt: '0.125rem',
          textDecoration: 'none',
          // this is needed to vertical-align the text to the optional icon
          '&&': {
            '& svg': {
              position: 'relative',
              top: '0.16rem',
            },
          },
        }}
      />
    )
  );
};
